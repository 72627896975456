<template>
  <div class="PaperStarting">
    <div class="fluid container">
      <article class="content">
            <div class="banner">
                <a class="banner-item" href="javascript:;">
                    <img src="https://paper.cnpkp.com/images/banner1.png" alt="">
                </a>
            </div>
            <div class="last-news index-news">
                <div class="news-content">
                    <div class="header">
                        <div class="title">
                            最新文章
                        </div>
                        <a class="more" href="javascript:;">
                            更多
                        </a>
                    </div>
                    <ul>
                        <li class="news-item first-paper">
                            <div class="detail">
                                <img src="https://paper.cnpkp.com/images/article1.png" alt="文章缩略图" />
                                <div class="desc">
                                    <div>
                                        <a href="javascript:;" class="classfication">教育信息化</a>
                                        <a href="javascript:;">信息技术在小学数学课堂教学中的运用</a>
                                    </div>
                                    <ul class="authors">
                                        <li >
                                            <span>陈静</span>
                                            <span class="order">1</span>
                                        </li>
                                        <div class="clearfix"></div>
                                    </ul>
                                    <p>2021-11-02</p>
                                    <div class="abstract">数学新课标明确提出，教师要改变落后的教学行为，重视现代信息技术的开发与应用，充分考虑计算机、多媒体等因素对数学学习内容、学习方式的影响，以此促进教学效果的优化和提升。对此，广大小学数学教师应做到与时俱进，树立科学的观念，把握现代教育教学的发展趋势，探索和运用创新的策略方法，实现信息技术在小学数学教学中的巧妙应用，取得良好的教学效果。</div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </li>
                        <li class="news-item">
                            <a href="javascript:;" class="classfication">教育信息化</a>
                            <a href="javascript:;">浅谈小学数学教学现代信息技术运用</a>
                            <p>2021-11-02</p>
                        </li>
                        <li class="news-item">
                            <a href="javascript:;" class="classfication">教育信息化</a>
                            <a class="hot-item" href="javascript:;">信息化背景下的校园网络精细化管理</a>
                            <p>2021-11-02</p>
                        </li>
                        <li class="news-item">
                            <a href="javascript:;" class="classfication">教育信息化</a>
                            <a href="javascript:;">基于云计算与大数据的高校个性化教学实践探索</a>
                            <p>2021-11-02</p>
                        </li>
                        <li class="news-item">
                            <a href="javascript:;" class="classfication">教育信息化</a>
                            <a href="javascript:;">信息技术在初中化学教学中的运用策略探讨</a>
                            <p>2021-11-02</p>
                        </li>
                        <li class="news-item">
                            <a href="javascript:;" class="classfication">教育信息化</a>
                            <a href="javascript:;">中职阶段信息化教学模式下落实课程思政的研究与探索</a>
                            <p>2021-11-02</p>
                        </li>
                        <li class="news-item">
                            <a href="javascript:;" class="classfication">教育信息化</a>
                            <a href="javascript:;">基于VPN技术的高校资源扩展探索与建设</a>
                            <p>2021-11-02</p>
                        </li>
                        <li class="news-item">
                            <a href="javascript:;" class="classfication">教育信息化</a>
                            <a href="javascript:;">大数据环境下高职计算机基础课程精准教学的分析</a>
                            <p>2021-11-02</p>
                        </li>
                        <li class="news-item">
                            <a href="javascript:;" class="classfication">教育信息化</a>
                            <a href="javascript:;">信息技术与教学课程融合策略</a>
                            <p>2021-11-02</p>
                        </li>
                        <li class="news-item">
                            <a href="javascript:;" class="classfication">IT新技术</a>
                            <a href="javascript:;">数字化时代公共文化服务延伸的思考分析</a>
                            <p>2021-11-02</p>
                        </li>
                        <li class="news-item">
                            <a href="javascript:;" class="classfication">IT新技术</a>
                            <a href="javascript:;">计算机信息系统维护效率提升策略研究</a>
                            <p>2021-11-02</p>
                        </li>
                        <li class="news-item">
                            <a href="javascript:;" class="classfication">计算机科学</a>
                            <a href="javascript:;">高职院校大数据课程项目式教学实践与探讨</a>
                            <p>2021-11-02</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="side-item side-banner2">
                <img src="https://paper.cnpkp.com/images/banner2.png" alt="">
            </div>
        </article>
        <div class="sidebar">
            <div class="side-item">
                <div class="paper-submit-button" style="margin-top: 16px;overflow: hidden;position: relative;">
                    <a href="http://www.cn119236.com"style="margin-top: 5px;text-align: center;">
                        <img src="https://paper.cnpkp.com/images/kanhao2.png" style="width: 32px;height: 32px;vertical-align: middle;margin-top: -5px;">
                        电脑校园
                    </a>
                </div>
            </div>
            <div class="side-item">
                <div class="li-content">
                    <div class="header">
                        <div class="title">
                            热门文章
                        </div>
                        <a class="more" href="javascript:;">
                            更多
                        </a>
                    </div>
                    <ul>
                        <li class="li-item">
                            <span class="order">1</span><a class="hot-item" href="javascript:;">中职学校机电一体化专业实践教学的策略分析</a>
                        </li>
                        <li class="li-item">
                            <span class="order">2</span><a class="hot-item" href="javascript:;">计算机技术在环境污染源信息化管理中的应用</a>
                        </li>
                        <li class="li-item">
                            <span class="order">3</span><a class="hot-item" href="javascript:;">信息化背景下的校园网络精细化管理</a>
                        </li>
                        <li class="li-item">
                            <span class="order">4</span><a class="hot-item" href="javascript:;">中职阶段信息化教学模式下落实课程思政的研究与探索</a>
                        </li>
                        <li class="li-item">
                            <span class="order">5</span><a class="hot-item" href="javascript:;">信息技术在初中化学教学中的运用策略探讨</a>
                        </li>
                        <li class="li-item">
                            <span class="order">6</span><a class="hot-item" href="javascript:;">5G时代下VR技术在教学中的应用</a>
                        </li>
                        <li class="li-item">
                            <span class="order">7</span><a class="hot-item" href="javascript:;">浅谈小学数学教学现代信息技术运用</a>
                        </li>
                        <li class="li-item">
                            <span class="order">8</span><a class="hot-item" href="javascript:;">网络环境下中职班主任如何开展德育工作</a>
                        </li>
                        <li class="li-item">
                            <span class="order">9</span><a class="hot-item" href="javascript:;">基于VPN技术的高校资源扩展探索与建设</a>
                        </li>
                        <li class="li-item">
                            <span class="order">10</span><a class="hot-item" href="javascript:;">浅谈大数据时代计算机网络安全存在的问题及解决对策</a>
                        </li>
                    </ul>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div class="side-item side-item2">
                <div class="li-content">
                    <div class="header">
                        <div class="title">
                            推荐阅读
                        </div>
                        <a class="more" href="javascript:;">
                            更多
                        </a>
                    </div>
                    <div class="first-li-item">
                        <a class="hot-item" href="javascript:;">
                            <img src="https://paper.cnpkp.com/images/article2.png" alt="">
                            <div>
                                <p>中职学校机电一体化专业实践教学的策略分析</p>
                                <span>IT新技术</span>
                            </div>
                            <div class="clearfix"></div>
                        </a>
                    </div>
                    <ul>
                        <li class="li-item">
                            <a class="hot-item" href="javascript:;">5G时代下VR技术在教学中的应用</a>
                        </li>
                        <li class="li-item">
                            <a class="hot-item" href="javascript:;">网络环境下中职班主任如何开展德育工作</a>
                        </li>
                        <li class="li-item">
                            <a class="hot-item" href="javascript:;">漫谈中职计算机应用基础有效施教的策略</a>
                        </li>
                        <li class="li-item">
                            <a class="hot-item" href="javascript:;">关于运用现代教育技术促进中职计算机教学改革的策略分析</a>
                        </li>
                        <li class="li-item">
                            <a class="hot-item" href="javascript:;">嵌入式系统的开发与应用研究</a>
                        </li>
                    </ul>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>

        <div class="news_type">
            <div class="header">
                <div class="title">
                    文章分类
                </div>
                <a class="more" href="javascript:;">
                    更多
                </a>
            </div>
            <div class="type_inner">
                <div class="flex">
                    <div class="type_li">
                        <div class="hot_title2"><span>IT新技术</span></div>
                        <ul>
                            <li><a href="javascript:;">计算机应用技术在工程项目管理中的实践探索</a></li>
                            <li><a href="javascript:;">无损检测技术在特种设备检验中的应用</a></li>
                            <li><a href="javascript:;">分析5G移动通信时代的智慧广电网络建设</a></li>
                            <li><a href="javascript:;">智能技术在电子工程的应用</a></li>
                            <li><a href="javascript:;">一种Schnorr型的改进多重数字签名方案</a></li>
                            <li><a href="javascript:;">基于“互联网+基于椭圆曲线Schnorr型广播多重数字签名的改进方案</a></li>
                        </ul>
                        <a class="more" href="paper/list.html">更多</a>
                        <div class="clearfix"></div>
                    </div>
                    <div class="type_li">
                        <div class="hot_title2"><span>校园论坛</span></div>
                        <ul>
                            <li><a href="javascript:;">客车电子防滑器故障原因分析及检修防范措施</a></li>
                            <li><a href="javascript:;">浅谈工程预算在市政工程造价控制中的潜在价值和应用</a></li>
                            <li><a href="javascript:;">河北红色文化的优势与实质</a></li>
                            <li><a href="javascript:;">台区线损精益化管理提升分析</a></li>
                            <li><a href="javascript:;">浅谈风景园林工程中软质景观和硬质景观的施工探讨</a></li>
                            <li><a href="javascript:;">机械自动化技术及其在机械制造中的应用分析</a></li>
                        </ul>
                        <a class="more" href="javascript:;">更多</a>
                        <div class="clearfix"></div>
                    </div>
                    <div class="type_li">
                        <div class="hot_title2"><span>教学研究</span></div>
                        <ul>
                            <li><a href="javascript:;">亲顾客偏离行为的顾客响应研究：基于道德情绪的理论视角心理学</a></li>
                            <li><a href="javascript:;">学生的作文素材来源于生活</a></li>
                            <li><a href="javascript:;">有关《国际贸易实务》课堂教学的思考</a></li>
                            <li><a href="javascript:;">创客教育理念下的农村小学编程教学探究</a></li>
                            <li><a href="javascript:;">高职院校计算机基础教学改革的研究及探索</a></li>
                            <li><a href="javascript:;">高职“电子产品生产工艺”课程教学探讨</a></li>
                        </ul>
                        <a class="more" href="javascript:;">更多</a>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div class="flex">
                    <div class="type_li">
                        <div class="hot_title2"><span>人工智能</span></div>
                        <ul>
                            <li><a href="javascript:;">人工智能在新一代通信中的应用思考</a></li>
                            <li><a href="javascript:;">基于人工智能图像识别领域专利布局及技术发展趋势分析</a></li>
                            <li><a href="javascript:;">计算机网络维护与管理的方法和技术研究</a></li>
                            <li><a href="javascript:;">反窃电检查中电力营销大数据的应用</a></li>
                            <li><a href="javascript:;">大数据环境下的审计质量控制难点与方法</a></li>
                            <li><a href="javascript:;">智能电网时代电力信息通信技术的应用</a></li>
                        </ul>
                        <a class="more" href="javascript:;">更多</a>
                        <div class="clearfix"></div>
                    </div>
                    <div class="type_li">
                        <div class="hot_title2"><span>智慧校园</span></div>
                        <ul>
                            <li><a href="javascript:;">提升初中历史课堂教学有效性的措施探究</a></li>
                            <li><a href="javascript:;">高中物理教学中如何提高学生的抽象思维能力研究</a></li>
                            <li><a href="javascript:;">浅谈幼儿心理健康教育</a></li>
                            <li><a href="javascript:;">高中物理图像法教学的有效开展研究</a></li>
                            <li><a href="javascript:;">刍议如何构建小学数学高效课堂</a></li>
                            <li><a href="javascript:;">聚焦核心素养，打造生本高效课堂——以高中语文教学为例</a></li>
                        </ul>
                        <a class="more" href="javascript:;">更多</a>
                        <div class="clearfix"></div>
                    </div>
                    <div class="type_li">
                        <div class="hot_title2"><span>教育信息化</span></div>
                        <ul>
                            <li><a href="javascript:;">信息技术在初中化学教学中的运用策略探讨</a></li>
                            <li><a href="javascript:;">关于初中英语多媒体教学存在的问题与对策研究</a></li>
                            <li><a href="javascript:;">正确使用网络视角下信息技术课堂教学探索</a></li>
                            <li><a href="javascript:;">小学数学教学中信息技术的运用</a></li>
                            <li><a href="javascript:;">浅议微课教学和小学数学课堂的有效结合</a></li>
                            <li><a href="javascript:;">初中英语教学中多媒体技术的运用</a></li>
                        </ul>
                        <a class="more" href="javascript:;">更多</a>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="link-wrap">
            <div class="header">
                <div class="title">
                    合作单位
                </div>
            </div>
            <ul class="flex">
                <li><img src="https://paper.cnpkp.com/images/propaganda_img14.jpg" alt=""><p>中国大百科全书数据库</p></li>
                <li><img src="https://paper.cnpkp.com/images/propaganda_img15.jpg" alt=""><p>武汉大学</p></li>
                <li><img src="https://paper.cnpkp.com/images/propaganda_img16.jpg" alt=""><p>中国数字图书馆</p></li>
                <li><img src="https://paper.cnpkp.com/images/propaganda_img17.jpg" alt=""><p>万方数据知识服务平台</p></li>
            </ul>
        </div>

        <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaperStarting'
}
</script>

<style scoped>
  a{text-decoration: none;}
  .top-wrap .user-wrap{
    width: 100%;
    background: #F0F4F7;
    opacity: 0.88;
    margin:0;
    padding:0;
}
.top-wrap .user-wrap .main-container{
    position: relative;
    width: 1008px;
    height: 40px;
    line-height: 40px;
    margin:0 auto;
}
.top-wrap .user-wrap  .main-container>span{
    display: inline-block;
    height: 14px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 24px;
    color: #3F3F3F;
    opacity: 1;
}
.top-wrap .user-wrap  .main-container .user-operation{
    position:absolute;
    height: 20px;
    top:50%;
    margin-top:-10px;
    right:0;
}
.top-wrap .embed-wrap {
    height: 148px;
    width: 100%;
    background: #F0F7FF;
}
.top-wrap .embed-wrap  .top-bg{
    height: 100%;
    width: 1008px;
    margin:0 auto;
    background:url('https://paper.cnpkp.com/images/top-wrap.png') no-repeat center;
}
.header-wrap{
    position: relative;
    width: 100%;
    height: 122px;
    background-color: #0051BA;
}

.header-wrap .left-bg{
    position: absolute;
    left:0;
    top:0;
    width: 240px;
    height: 122px;
    background:url('https://paper.cnpkp.com/images/bg-zs.png') left no-repeat;
    background-size: 100% 100%;

}
.header-wrap .right-bg{
    position: absolute;
    right:0;
    top:0;
    width: 240px;
    height: 122px;
    background:url('https://paper.cnpkp.com/images/bg-zs.png') right no-repeat;
    background-size: 100% 100%;
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
.header-wrap .left-bg img,
.header-wrap .right-bg img{
    height: 100%;
    border:none;
}
.header-wrap .right-bg img{
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
.header-wrap  .header{
    margin:0 auto;
}
.header-wrap .header .top{
    position: relative;
    height: 30px;
    padding-top:11px;
}
.header-wrap .header .top .guidance{
    height: 18px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    line-height: 18px;
}
.header-wrap .header .top .issues{
    height: 18px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    line-height: 18px;
}
.header-wrap .header .top .login{
    position: absolute;
    right: 0;
    top: 11px;
}
.user-button{
    float: left;
    display: block;
    width: 40px;
    height: 24px;
    border-radius: 2px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    line-height: 24px;
    text-align: center;
}
.login-button{
    background-color: #107DE3;
    margin-right: 24px;
}
.register-button{
    background-color: #FAAC3F;
}
.user-menu{
    position: relative;
    display: block;
}
.user-menu>a{
    position: relative;
    display: block;
    height: 24px;
    line-height: 24px;
    padding-right:16px;
    background: url(https://paper.cnpkp.com/images/bottom_arrow_icon.png) right center no-repeat;
    background-size: 16px 8px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
}
.user-menu .user-image {
    float: left;
    width: 24px;
    height: 24px;
}
.user-menu>span{
    display: inline-block;
    vertical-align: middle;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10000;
    display: none;
    float: left;
    width: 130px;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
.dropdown-menu>li{
    display: block;
    padding: 8px 20px;
}
.dropdown-menu>li>a{
    display: block;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
}
.dropdown-menu>li:hover{
    background: rgba(0, 0, 0, 0.06);
}
.open .dropdown-menu{
    display: block !important;
}
.header-wrap  .header .search-wrap{
    height: 69px;
    line-height: 69px;
    
}
.header-wrap  .header .search-wrap .logo{
    float: left;
    display: block;
    box-sizing: border-box;
    width: 400px;
    height: 100%;
    padding:11px 39px 11px 0;
}
.header-wrap .header .search-wrap .logo img{
    width: 100%;
    border:none;
}
.header-wrap  .header .search-wrap .search{
    position: relative;
    float: left;
    display: block;
    width: 480px;
    height: 69px;
    line-height: 69px;
    margin-left:40px;
}
.header-wrap .header .search-wrap .search input{
    width: 480px;
    height: 48px;
    box-sizing: border-box;
    border:1px solid rgba(255, 255, 255, 0.85);
    border-radius: 4px;
    background-color: #FFF;
    padding: 8px 50px 8px 16px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.35);
}
.header-wrap .header .search-wrap .search .search-button{
    position: absolute;
    display: block;
    right: 15px;
    top:50%;
    width: 20px;
    height: 20px;
    margin-top:-10px;
    border:none;
    background: url(https://paper.cnpkp.com/images/search_blue.png);
    background-size: 100% 100%;
}

.header-wrap .header .search-wrap .advance-search{
    position: relative;
    color:#fff;
    height:20px;
    width:48px;
    padding-right: 20px;
    margin-left: 15px;
    font-size: 12px;
    font-weight: 400;
    background:url(https://paper.cnpkp.com/images/right_arrow_white.png) right no-repeat;
    background-size: 20px 20px;
}

.header-wrap .menu{
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
}

.header-wrap .menu .nav{
    margin:0 auto;

}
.header-wrap .menu .nav a{
    float: left;
    display: block;
    height: 54px;
    line-height: 54px;
    font-size: 16px;
    color:rgba(255, 255, 255, 0.85);
    margin-right: 32px;
}
.header-wrap .menu .nav .active,
.header-wrap .menu .nav a:hover{
    color:#fff;
} 

.fluid{
    position: relative;
    margin:0 auto;
}
.main-warp:after{
    display: block;
    content: " ";
    clear: both;
}
.important-news{
    margin-top:16px;
}
.important-news>a{
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(34, 55, 94, 0.85);
    line-height: 20px;
}
.important-news span{
    width: 40px;
    background: #FFDE00;
    border-radius: 2px;
    font-size: 12px;
    font-family: DIN-Medium, DIN;
    font-weight: 500;
    color: #0051BA;
    margin-right: 8px;
}

.fluid .content{
    float: left;
    display: block;
    width: 738px;
    margin-right: 8px;
}

.fluid .banner{
    margin-top:16px;
}
.fluid .banner img{
    width: 690px;
    height: 84px;
    border:none;
}
.fluid .content .last-news{
   display: block;
   overflow: hidden;
}
.fluid .header,
.link-wrap .header{
    position: relative;
    /*height: 24px;*/
    /*line-height: 24px;*/
    margin:24px 0 8px 0;
}
.fluid .header .title,
.link-wrap .header .title{
    height: 24px;
    font-size: 18px;
    font-family: PingFangSCHeavy;
    color: rgba(0, 0, 0, 0.75);
    line-height: 24px;
    border-left:4px solid #0051BA;
    font-weight: 700;
    padding-left: 12px;
}
.fluid .header .more,
.link-wrap .header .more{
    position: absolute;
    right:0;
    bottom: 0;
    height: 20px;
    line-height: 20px;
    padding-right: 20px;
    color: #107DE3;
    background:url(https://paper.cnpkp.com/images/right_arrow_blue.png) right no-repeat;
    background-size: 20px 20px;
    font-size: 12px;
    font-weight: 400;
}

.fluid .content .last-news  .news-content{
    background-color: #fff;
    padding: 16px;
    display: block;
}
.fluid .content .last-news  .news-content .news-item{
    display: block;
    width: 100%;
    margin-bottom: 8px;
    overflow: hidden;
    height: 20px;
    line-height: 20px;
}

.fluid .content .last-news  .news-content .news-item .new-tip{
    float:left;
    display: block;
    height: 16px;
    box-sizing: border-box;
    background: #FFDE00;
    border-radius: 2px;
    line-height: 16px;
    font-size: 12px;
    font-family: DIN-Medium, DIN;
    font-weight: 500;
    color: #0051BA;
    padding: 0px 8px;
    text-align: center;
    margin-top:2px;
    margin-right:4px;
}
.fluid .content .last-news  .news-content .news-item a{
    float: left;
    display: block;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 14px;
    color: rgba(40, 66, 99, 0.85);
    max-width: 560px;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
}
.fluid .content .last-news  .news-content .news-item:first-child a{
    color:#0051BA;
}
.fluid .content .last-news  .news-content .news-item:last-child{
    margin-bottom: 4px;
}
.fluid .content .last-news  .news-content .news-item .detail{
    float: left;
    height: 120px;
    padding: 8px 0;
}
.fluid .content .last-news  .news-content .news-item .detail img{
    float: left;
    height: 120px;
    width: 180px;
    border:none;
    border-radius: 5px;
}
.fluid .content .last-news  .news-content .news-item .detail .desc{
    float: left;
    width: 458px;
    margin-left:16px;
}

.fluid .content .last-news  .news-content .news-item .detail .desc li{
    position: relative;
    float: left;
    margin-right: 15px;
    font-size: 14px;
    color: rgba(40, 66, 99, 0.85);
    padding-right: 10px;
}
.fluid .content .last-news  .news-content .news-item .detail .desc li .order{
    position: absolute;
    right:0;
    top:-5px;
    height: 18px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 18px;
}
.fluid .content .last-news  .news-content .news-item .detail .desc .abstract{
    float: left;
    width: 100%;
    height: 80px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color:rgba(0, 0, 0, 0.65);
    margin-top:8px;
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 4; 
    overflow: hidden;
}
/* 文章分类部分 */
.fluid .content .last-news  .news-content .article-category{
    float: left;
    display: block;
    box-sizing: border-box;
    width: 30%;
    height: 24px;
    line-height: 24px;
    margin-right:16px;
    margin-bottom: 0px;
}
.fluid .content .last-news  .news-content .article-category:nth-child(n+3){
    margin-bottom:4px;
}
.fluid .content .last-news  .news-content .article-category a{
    display: block;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(40, 66, 99, 0.85);
    line-height: 20px;
    overflow: hidden;
}
.fluid .content .last-news  .news-content .article-category:nth-child(3n){
    margin-right: 0;
}
/* 右边导航部分 */
.fluid .sidebar{
    position: absolute;
    top: 0;
    right: 0;
    width: 354px;
}
.fluid .sidebar .article-total{
    width: 272px;
    height: 48px;
    line-height: 48px;
    background: #FFFFFF;
    padding:18px 16px;
    overflow: hidden;
}
.fluid .sidebar .article-total img{
    float: left;
    width: 48px;
    height: 48px;
    border:none;
}
.fluid .sidebar .article-total .text{
    float: left;
    font-size: 32px;
    font-family: DIN-Black, DIN;
    font-weight: 900;
    height: 40px;
    color: #0051BA;
    margin-left: 16px;
}
.fluid .sidebar .policy{
    width: 272px;
    padding:16px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    background:#FFFFFF;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0051BA;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

}
.fluid .sidebar .li-content{
    width:272px;
    padding:16px;
    background:#FFFFFF;
}

.fluid .sidebar .li-content .li-item{
    display: block;
    margin-bottom: 8px;
    overflow: hidden;
}
.fluid .sidebar .li-content .li-item:last-child{
    margin-bottom: 0;
}
.fluid .sidebar .li-content .li-item a{
    float: left;
    display: block;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 14px;
    color: rgba(40, 66, 99, 0.85);
    width: 272px;
    height: 20px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.fluid .sidebar .li-content .li-item .hot-item{
    width: 238px;
}
.first-paper{
    height: 148px !important;
    background:#FFFFFF;
}
.main-color{
    color: #0051BA !important;
}
.paper-category .news-content{
    padding-bottom: 12px !important;
}
.fluid .content .last-news  .news-content .news-item .classfication,
.fluid .sidebar .li-content .li-item .order
{
    float: left;
    display: block;
    height: 14px;
    line-height: 14px;
    background: rgba(0, 81, 186, 0.1);
    border-radius: 2px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0051BA;
    padding: 3px 8px;
    text-align: center;
    margin-right: 4px;
}

/* 首页友情链接部分 */
.link-wrap{
    position: relative;
    margin:0 auto;
    display: block;
}
.link-wrap .link-content{
    width:100%;
    box-sizing: border-box;
    padding:16px;
    background:#FFFFFF;
}
.link-wrap .link-content .link-item{
    float: left;
    display: block;
    box-sizing: border-box;
    width: 25%;
    height: 24px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(40, 66, 99, 0.85);
    line-height: 20px;
    padding-right:16px;
    margin-bottom: 4px;
}
.link-wrap .link-content .link-item:nth-child(4n){
    padding-right: 0;
}

.link-wrap .link-content .link-item a{
    display: block;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 14px;
    color: rgba(40, 66, 99, 0.85);
    max-width: 560px;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
}
/* 文章浏览导航 */
.fluid .nav-category{
  float: left;
  width: 240px;
  margin-right: 16px;
}
 
.fluid .nav-category .header{
    position: relative;
    height: 56px;
    margin: 0;
} 
.fluid .nav-category .header .title{
    display: block;
    height: 24px;
    font-size: 18px;
    font-family: PingFangSCHeavy;
    color: rgba(0, 0, 0, 0.75);
    line-height: 24px;
    border-left:4px solid  rgba(0, 81, 186, 0.2);
    font-weight: 700;
    padding: 16px 20px;
}

.fluid .nav-category .nav-item{
    background-color: transparent;
}
.paper-submit-button{
    height: 48px;
    background: #107DE3;
    box-shadow: 0px 4px 8px 0px rgba(0, 81, 186, 0.25);
    border-radius: 4px;
    padding: 18px 36px;
    margin-top:36px;
}
.paper-submit-button>a{
    display: block;
    height: 48px;
    font-size: 24px;
    font-family: PingFangSCHeavy;
    color: #FFFFFF;
}
.paper-submit-button img{
    height: 48px;
    width: 48px;
    vertical-align: middle;
}

.fluid .nav-category .nav-item li{
    height: 20px;
    line-height: 20px;
    width: 192px;
    padding:8px 24px;
    margin-bottom: 8px;
}
.fluid .nav-category .nav-item li:last-child{
    margin-bottom: 0;
}
.fluid .nav-category .nav-item li a{
    display: block;
    line-height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0051BA;
    line-height: 20px;
}
/* 文章浏览主页面 */
.fluid .content .search-wrap{
    position: relative;
    height: 48px;
    line-height: 48px;
    overflow: hidden;
    border-bottom: 1px solid #F9F9FA;
}
.fluid .content .search-wrap .title{
    float: left;
    display: block;
    width: 112px;
    background: #0051BA;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    
    text-align: center;
}

.fluid .content .search-wrap .search-item{
    float: left;
    display: block;
    /*height: 48px;*/
    /*line-height: 48px;*/
    /*margin-left: 24px;*/
}
.fluid .content .search-wrap .search-item a{
    display: block;
    height: 48px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(34, 55, 94, 0.85);
    line-height: 48px;
    padding-left: 24px;
    padding-right: 20px;
    background:url(https://paper.cnpkp.com/images/arrow_bottom_nor.png) right center no-repeat;
    background-size: 16px 8px;
}
.fluid .content .search-wrap .search-item .bottom-active{
    background:url(https://paper.cnpkp.com/images/arrow_bottom_sort.png) right center no-repeat;
    background-size: 16px 8px;
}
.fluid .content .search-wrap .search-item .top-active{
    background:url(https://paper.cnpkp.com/images/arrow_top_sort.png) right center no-repeat;
    background-size: 16px 8px; 
}
.fluid .content .search-wrap .search-input{
    float: right;
    position: relative;
    margin-right:8px;
}
.open-asc>a{
    background:url(https://paper.cnpkp.com/images/arrow_top_sort.png) right center no-repeat !important;
    background-size: 16px 8px !important;
}
.open-desc>a{
    background:url(https://paper.cnpkp.com/images/arrow_bottom_sort.png) right center no-repeat !important;
    background-size: 16px 8px !important;
}
.fluid .content .search-wrap .search-input input{
    width: 160px;
    height: 16px;
    border-radius: 2px;
    border: 1px solid #DDDDDD;
    padding:6px 32px 6px 12px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
}
.fluid .content .search-wrap .search-input a{
    position: absolute;
    display: block;
    right:8px;
    top:50%;
    margin-top:-8px;
    width: 16px;
    height: 16px;
    background: url(https://paper.cnpkp.com/images/search_gray.png) no-repeat;
    background-size: 100% 100%;
}

.fluid .content  .selection-wrap{
    margin:16px;
    display: block;
}
.fluid .content  .selection-wrap>span{
    height: 20px;
    display: inline-block;
}
.fluid .content .selection-wrap .lbl,
.fluid .content .selection-wrap .info{
    float: left;
}
.fluid .content .selection-wrap .lbl{
    color: rgba(0, 0, 0, 0.35);
}
.fluid .content .selection-wrap .info{
    color: #0051BA;
    margin-left:5px;
    max-width: 627px;
    line-height: 20px;
}
.fluid .find-result-tips{
    width: 100%;
    height: 568px;
    background: #fff;
}
.fluid .find-result-tips .find-result-content{
    padding-top:128px;
    height: 400px;
    text-align: center;
}
.fluid .find-result-tips .find-result-content>img{
    width: auto;
    height: 64px;
}
.fluid .find-result-tips .find-result-content>p{
    height: 24px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #E02020;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom:16px;
}
.fluid .find-result-tips .find-result-content a{
    display: block;
    width: 224px;
    height: 40px;
    background: #0051BA;
    border-radius: 2px;
    font-size: 14px;
    font-family: PingFangSCHeavy;
    color: #FFFFFF;
    line-height: 40px;
    margin:0 auto;
}

.fluid .find-result-tips .find-result-content .reason{
    margin:16px auto;
    width: 192px;
    height: 80px;
    background: #F9FBFF;
    border-radius: 4px;
    padding:16px;

}
.fluid .find-result-tips .find-result-content .reason li{
    display: block;
    height: 20px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 20px;
    text-align: left;
}
.fluid .find-result-tips .find-result-content .reason li>b{
    font-size: 14px;
    color:rgba(0, 0, 0, 0.85);
}

.article-wrap{
    width: 752px !important;
    min-height: 760px;
}
.fluid .content .article-list .article-item{
    /* width: 720px; */
    /*height: 216px;*/
    background: rgba(0, 81, 186, 0.02);
    box-shadow: 0px 4px 8px 0px rgba(0, 81, 186, 0.08);
    border-radius: 4px;
    margin:0 16px 19px;
    overflow: hidden;
    padding-bottom: 16px;
}
.fluid .content .article-list .article-item .doi-wrap{
    margin:16px 16px 8px 16px;
    height: 20px;
    line-height: 20px;
}
.fluid .content .article-list .article-item .doi-wrap .order{
    display:inline-block;
    width: 20px;
    height: 20px;
    background: rgba(0, 81, 186, 0.1);
    border-radius: 2px;
    /* width: 14px;
    height: 14px; */
    font-size: 12px;
    font-family: PingFangSCHeavy;
    color: #0051BA;
    text-align: center;
}
.fluid .content .article-list .article-item .doi-wrap .doi{
    margin-left:8px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(40, 66, 99, 0.85);
}
.fluid .content .article-list .article-item a.title{
    display: block;
    padding:0 15px 0px 45px;
    height: 24px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0051BA;
    line-height: 24px;
    margin-bottom: 4px;
}
.fluid .content .article-list .article-item .author{
    margin-left:45px;
    margin-bottom: 5px;
}
.fluid .content .article-list .article-item .author li{
    float:left;
    height: 20px;
    line-height: 20px;
    margin:0 7px 0 0;
}
.fluid .content .article-list .article-item .author li a{
    display: block;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(40, 66, 99, 0.85);
}

.fluid .content .article-list .article-item .category{
    margin-left:45px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.35);
    line-height: 18px;
    margin-bottom: 5px;
}
.fluid .content .article-list .article-item .category li{
    float:left;
    height: 18px;
    line-height: 18px;
    margin-right: 5px;
}
.fluid .content .article-list .article-item .category li a{
    display: block;
    color: rgba(0, 0, 0, 0.35);
}

.fluid .content .article-list .article-item .abstract{
    /*height: 54px;*/
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 18px;
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 3; 
    overflow: hidden;
    margin-left:44px;
    margin-right:12px;
    margin-bottom: 8px;
}

.fluid .content .article-list .article-item .total{
    margin-left:45px;
    height: 18px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.35);
    line-height: 18px;
}
.fluid .content .article-list .article-item .total li{
    float:left;
    height: 20px;
    line-height: 20px;
    margin-right: 48px;
}
.fluid .content .article-list .article-item .total li b{
    font-size: 12px;
    font-family: PingFangSCHeavy;
    color: rgba(0, 0, 0, 0.85);
    margin-left:4px;
}
.fluid .pagination{
    float: right;
    margin-bottom: 16px;
}
.fluid .pagination li{
    float: left;
    display: block;
    /*width: 32px;*/
    margin-right: 8px;
}
.fluid .pagination li span,
.fluid .pagination li a{
    display: block;
    height: 32px;
    line-height: 32px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #DDDDDD;
    text-align: center;
    padding:0 10px;
}
.pagination li a:hover,
.pagination li span.current {
    border: 1px solid #107DE3;
    color: #107DE3;
}
.fluid .pagination li:last-child{
    margin-right:16px;
    width: 96px;
    border:none;
}
.fluid .pagination li:first-child,
.fluid .pagination li:first-child span{
    border:none;
}
.fluid .pagination li:last-child select{
    width: 96px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #DDDDDD;
    padding-left:15px;
}
.main-category{
    margin-top:16px !important;
}

/*文章详情部分*/
.breadcrumb-warp{
    margin:8px auto 0px;
}
.breadcrumb-warp .breadcrumb{
    margin:8px 0;
}
.breadcrumb-warp .breadcrumb li{
    position: relative;
    float: left;
    display: block;
    height: 20px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 20px;
}
.breadcrumb-warp .breadcrumb li .arrow{
    width: 20px;
    height: 20px;
    display: inline-block;
    border: none;
    background:url(https://paper.cnpkp.com/images/arrow_brandcrumb.png) center no-repeat;
    background-size: 100% 100%;
}
.breadcrumb-warp .breadcrumb li a{
    display: block;
    color: rgba(34, 55, 94, 0.85);
}

.fluid .content  .article-detail{
    background: #FFFFFF;
    padding: 21px 16px;
}
.fluid .content  .article-detail .tips{
    height: 90px;
    background: rgba(255, 231, 186, 0.2);
    border: 1px solid #FFD591;
    padding: 16px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 18px;
}
.fluid .content  .article-detail .tips>a{
    height: 18px;
    color: #0051BA;
    line-height: 18px;
}
.fluid .content  .article-detail .title{
    height: 24px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0051BA;
    line-height: 24px;
    margin:16px 6px 6px 0px;
    overflow: hidden;
}

.fluid .content  .article-detail .authors li:first-child{
    padding-right: 0;
}
.fluid .content  .article-detail li{
    position: relative;
    float: left;
    margin-right: 8px;
    padding-right: 10px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.35);
    line-height: 18px;
}
.fluid .content  .article-detail li:first-child{
    padding-right: 0;
}
.fluid .content  .article-detail li>a{
    display: block;
    font-size: 12px;
    color: rgba(40, 66, 99, 0.85);
}
.fluid .content .article-item .category li .arrow,
.fluid .content  .article-detail li .arrow{
    width: 18px;
    height: 18px;
    display: inline-block;
    border: none;
    background:url(https://paper.cnpkp.com/images/arrow_brandcrumb.png) center no-repeat;
    background-size: 100% 100%;
}

.fluid .content  .article-detail li .order{
    position: absolute;
    right:0;
    top:-5px;
    height: 18px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 18px;
}
.fluid .content .article-detail .commit-date{
    height: 18px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.35);
    line-height: 18px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.fluid .content  .article-detail .split-line{
    height: 1px;
    background: #DDDDDD;
    box-shadow: 0px 4px 8px 0px rgba(0, 81, 186, 0.08);
    border-radius: 4px;
    margin:16px 0;
}
.fluid .content  .article-detail>h3{
    height: 20px;
    font-size: 14px;
    font-family: PingFangSCHeavy;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
}
.fluid .content  .article-detail .abstract{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 18px;
    margin-top:8px;
}

.fluid .content  .article-detail .category,
.fluid .content  .article-detail .keywords,
.fluid .content  .article-detail .reference,
.fluid .content  .article-detail .doi,
.fluid .content  .article-detail .recommend-reference{
    margin-bottom: 8px;
}

.fluid .content  .article-detail .category li{
    padding-right: 0;
    margin-right: 0;
}
.fluid .content  .article-detail .doi li>a,
.fluid .content  .article-detail .reference li>a{
    color: #0051BA;
}
.fluid .content  .article-detail .recommend-paper li{
    display: block;
    width: 646px;
    position: relative;
    margin-bottom: 8px;
}
.fluid .content  .article-detail .recommend-paper li:first-child{
    margin-top:8px;
    padding-right: 10px;
}
.fluid .content  .article-detail .recommend-paper li>a{
    display: block;
    height: 18px;
    font-size: 12px;
    padding-right: 100px;
    font-weight: 400;
    color: #0051BA;
    line-height: 18px;
}
.fluid .content  .article-detail .recommend-paper li .date{
    position: absolute;
    right: 0;
    top: 0;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 17px;
}
.fluid .sidebar .operation-wrap{
    height: 96px;
    background: #FFFFFF;
    padding:24px 16px;
    overflow: hidden;
}
.fluid .sidebar .operation-wrap>a{
    display: block;
    height: 40px;
    line-height: 40px;
    background: #FFF;
    border-radius: 2px;
    border: 1px solid #0051BA;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSCHeavy;
    color: #0051BA;
}
.fluid .sidebar .operation-wrap>a:first-child{
    background: #0051BA;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.fluid .sidebar .last-view{
    background: #FFFFFF;
    padding: 16px;
}
.fluid .sidebar .last-view>a{
    float: left;
    display: block;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0051BA;
    line-height: 20px;
    margin-right: 32px;
}

/*资讯详情*/
.fluid .news-detail{
    width: 100%;
    padding:48px 72px;
    box-sizing: border-box;
    background: #FFFFFF;
}
.fluid .news-detail .title{
    height: 26px;
    font-size: 18px;
    font-family: PingFangSCHeavy;
    color: rgba(0, 0, 0, 0.85);
    line-height: 26px;
    margin-bottom: 8px;
    text-align: center;
}
.fluid .news-detail .time{
    height: 18px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.35);
    line-height: 18px;
    margin-bottom: 32px;
    text-align: center;
}
.fluid .news-detail .content{
    min-height: 552px;
    width: 100%;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
}
/*用户登录注册部分*/
.user-wrap{
    width: 720px;
    margin:80px auto 32px;
}
.user-wrap .header{
    position: relative;
    height: 45px;
    line-height: 45px;
    margin:24px 0 8px 0;
}
.user-wrap .header .title{
    height: 45px;
    font-size: 32px;
    font-family: PingFangSCHeavy;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    line-height: 45px;
}
.user-wrap .header .other{
    float: right;
    display: block;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
    margin-top: 7px;
}
.user-wrap .tab{
    width: 100%;
    margin-top: 32px;
    margin-bottom: 14px;
}
.user-wrap .tab li{
    position: relative;
    float: left;
    display: block;
    box-sizing: border-box;
    width: 33.3%;
    height: 48px;
    line-height: 48px;
    background: #FFFFFF;
    text-align: center;
    cursor: pointer;
}
.user-wrap .tab li.active{
    background: #0051BA;
    color:#FFF;
}
.user-wrap .tab li img{
    vertical-align: middle;
    height: 32px;
}
.user-wrap .tab li>span{
    display: inline-block;
    padding-left: 36px;
}
.user-wrap .tab li:nth-child(1)>span{
    background: url(https://paper.cnpkp.com/images/personal_gray_icon.png) left center no-repeat;
    background-size: 32px 32px;
}
.user-wrap .tab li.active:nth-child(1)>span{
    background: url(https://paper.cnpkp.com/images/personal_white_icon.png) left center no-repeat;
    background-size: 32px 32px;
}
.user-wrap .tab li:nth-child(2)>span{
    background: url(https://paper.cnpkp.com/images/company_gray_icon.png) left center no-repeat;
    background-size: 32px 32px;
}
.user-wrap .tab li:nth-child(2).active>span{
    background: url(https://paper.cnpkp.com/images/company_white_icon.png) left center no-repeat;
    background-size: 32px 32px;
}
.user-wrap .tab li:nth-child(3)>span{
    background: url(https://paper.cnpkp.com/images/school_gray_icon.png) left center no-repeat;
    background-size: 32px 32px;
}
.user-wrap .tab li:nth-child(3).active>span{
    background: url(https://paper.cnpkp.com/images/school_white_icon.png) left center no-repeat;
    background-size: 32px 32px;
}
.user-wrap .tab li a{
    display: block;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    height: 48px;
    line-height: 48px;
    text-align: center;
}
.user-wrap .tab li.active a{
    color:#FFF;
}
.user-wrap .member-form{
    position: relative;
    margin-top:14px;
    background: #FFFFFF;
    min-height: 350px;
}
.member-form .login,
.member-form .register{
    margin:0 auto;
    padding:80px 0;
}
.member-form .user-login{
    width: 352px;
}
.member-form .user-register{
    width: 452px;
}
.user-wrap .member-form .form-group{
    margin-bottom: 32px;
}
.form-group:after{
    content:'';
    display:block;
    clear:both;
}
.user-wrap .member-form .form-label{
    height: 20px;
    font-size: 14px;
    font-family: PingFangSCHeavy;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.65);
    line-height: 20px;
    margin-bottom: 8px;
}
.member-form-container{
    width: 352px;
    margin:0 auto;
}
.member-form>.notice{
    position: absolute;
    top:50%;
    width: 100%;
    text-align:center;
}
/*自定义步骤条信息*/
.step-container{
    width: 452px;
    margin:0 auto 64px;
    height: 44px;
}
.step-container .step-item{
    position: relative;
    float: left;
    display: block;
}
.step-num{
    position: absolute;
    left:0;
    top:50%;
    margin-top:-16px;
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
    background: #0051BA;
    color: #FFF;
    text-align: center;
    opacity: 0.5;
}
.step-item.active .step-num{
    opacity: 1;
}
.step-item .step-content{
    display: inline-block;
    margin-left:8px;
    padding-left: 32px;
}
.step-line{
    height: 44px;
    width: 24px;
    margin:0 8px;
}
.line{
    position: absolute;
    top:50%;
    width: 24px;
    height: 1px;
    border-bottom: 1px solid #ddd;
/*    margin: 0 8px;*/
}
.step-item .step-content .title,
.step-item .step-content .sub-title
{
    height: 20px;
    font-size: 14px;
    font-family: PingFangSCHeavy;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
}
.step-item .step-content .sub-title{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    margin-top:4px;
}
/*用户登录及注册的tab页部分*/
.tab-content .tab-item{
    display: none;
}
.tab-active{
    display: block !important;
}
.form-control{
    display: block;
    width: 330px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    padding:8px 16px 8px 6px;
    border:none;
    border-bottom: 1px solid #979797;
}

.custom-checkbox{
    display: block;
    position: relative;
    vertical-align: middle;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.35);
}
.custom-checkbox span {
    position: absolute;
    top: 2px;
    left: 0;
    display: inline-block;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    background-color: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    z-index: 1;
}
.custom-checkbox input[type=checkbox] {
    visibility: hidden;
    margin-right: 10px;
}
.custom-checkbox span i {
    display: none;
}
.custom-checkbox.active span i {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAADd6iHDAAAAIVBMVEVHcExBfeI7ed47eN48ed48eN07euA/euI8ed09et87eN3WOxJjAAAACnRSTlMAFc/ZkPhJKLVgaUo6zgAAAEBJREFUCNdjYIACoQYYi9EKzhReJAAXVEQRLEcImibAVXqFwVWyLE2Aa/cKg2tnWToZbqbXKriZLHBBBoYCCAUAsNQNUHJPwIEAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-size: 10px 7px;
    background-position: 2px 4px;
    border-radius: 1px;
}
.service a, .service label {
    display: inline-block;
    line-height: 20px;
    height: 20px;
}
.service label {
    vertical-align: text-top;
}
.service a{
    color: #0051BA;
}

.member-form .login-button{
    display: inline-block;
    width: 352px;
    height: 48px;
    line-height: 48px;
    background: #0051BA;
    border-radius: 2px;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSCHeavy;
    color: #FFFFFF;
    margin-top:16px;
}
.member-form .forget-pwd,
.member-form .login-type-title{
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
    text-align: center;
    margin-top: 16px;
}
.member-form p.login-type-title{
    color: rgba(0, 0, 0, 0.45);
    margin-top:32px;
}
.member-form .login-type{
    height: 50px;
    text-align: center;
    margin-top:16px;
}
.member-form .login-type a{
    display: inline-block;
    text-align: center;
    width: 48px;
    height: 50px;
    overflow: hidden;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
}
.member-form .login-type a:first-child{
    margin-right: 32px;
}
.member-form .login-type .login-type-image {
    width: 32px;
    height: 32px;
    margin:0 auto;
}
.member-form .login-type a h6{
    display: inline-block;
    font-size: 12px;
    height: 17px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.member-form .forget-pwd>a{
    display: block;
}
.check-code-item{
    position: relative;
}
.check-code-item .form-control{
    box-sizing: border-box;
    width: 352px;
    height: 36px;
    padding-right: 85px;
}
 /* 注册页面获取检验码 */
.check-code-button{
    position: absolute;
    right: 5px;
    top:50%;
    margin-top:3px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0051BA;
    line-height: 20px;
}
.register>.line{
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #ddd;
    margin-top:32px;
    margin-bottom: 16px;
}
.form-enterprise{
    width: 624px;
    margin:0 auto !important;
}
.form-enterprise .header{
    position: relative;
    height: 24px;
    line-height: 24px;
    margin:16px 0;
    border-bottom:1px solid #ddd;
    padding: 8px 0;
}

.form-enterprise .header .title{
    display: inline-block;
    height: 24px;
    font-size: 16px;
    font-family: PingFangSCHeavy;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
}
.form-enterprise .header>a{
    display: inline-block;
    float: right;
    padding-right: 20px;
    height: 18px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0051BA;
    line-height: 18px;
    vertical-align: middle;
    background: url(https://paper.cnpkp.com/images/right_arrow_blue.png) right center no-repeat;
    background-size: 20px 20px;
}

.enterprise-info{
    width: 522px !important;
}
.enterprise-info .login-button{
    width: 496px;
    margin-right:0;
}
label.required:before {
    content: '* ';
    color: red;
}
.form-enterprise .form-group{
    position: relative;
    margin-bottom: 24px !important;
    min-height: 32px;
}
.form-enterprise .form-group .form-label{
    float:left;
    width: 155px;
    margin-right: 10px;
    height: 32px;
    line-height: 32px;
}
.form-enterprise .form-group .form-control{
    background: #F9F9FA;
    border: 1px solid #BBBBBB;
    padding:6px 12px;
}
.school-info{
    width: 598px !important;
}
.school-info .form-label{
    width: 185px !important;
}
.select-item{
    float: left;
    width: 96px;
    height: 32px;
    background: #F9F9FA;
    border: 1px solid #BBBBBB;
}
.select-item-split{
    float: left;
    position: relative;
    height: 30px;
    width: 32px;
}
.select-item-split>p{
    position: absolute;
    width: 12px;
    height: 2px;
    background: #DDDDDD;
    top:50%;
    margin-top:-1px;
    left: 50%;
    margin-left:-6px;
}
.register-option{
    text-align: center;
}

/*文件上传部分*/
.upload-image{
    position: relative;
    height: 128px;
    margin-bottom: 16px;
}
.upload-image:after{
    content:"";
    display:block;
    clear:both;
}

.upload-operation{
    position: absolute;
    width: 65px;
    height: 60px;
    top:50%;
    left:50%;
    margin-top:-30px;
    margin-left:-32px;
    cursor: pointer;
    z-index: 2;
}
.upload-operation>.add{
    display: block;
    height: 32px;
    line-height: 32px;
    font-size: 48px;
    text-align: center;
    color: rgba(0, 18, 22, 0.2);
}
.upload-operation>span{
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 18, 22, 0.65);
}
.upload-image .image{
    position: relative;
    float: left;
    width: 128px;
    height: 128px;
    text-align: center;
    background: #F9F9FA;
    border: 1px solid #BBBBBB;
    vertical-align:middle;
    overflow: hidden;
}
.upload-image .image .iblock {
    display:inline-block;
    height:100%;
    width:0;
    vertical-align:middle
}
.upload-image .image .title{
    position: absolute;
    left:-50px;
    top:0;
    width: 42px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 20px;
}
.upload-image .cover-mask{
    position: absolute;
    display: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(3,3,3,0.5);
    z-index: 1;
}
.upload-image img{
    width: 128px;
    height: 128px;
    border:none;
    display: none;
}
.upload-image .image:nth-child(3){
    overflow: visible;
}
.upload-image .image:nth-child(3) img{
    position: absolute;
    top:50%;
    left:0;
    height: auto;
    margin-top:-45px;

}
.upload-image .image:nth-child(3) img:hover {
    transform: scale(3);
    z-index: 100;
}
.license-tips{
    float: right;
    padding: 16px;
    width: 320px;
    background: #F9F9FA;
    border: 1px solid #BBBBBB;
}
.license-tips>h4{
    height: 24px;
    font-size: 16px;
    font-family: PingFangSCHeavy;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    margin-bottom: 8px;
}
.license-tips li{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(0, 0, 0, 0.65);
    line-height: 20px;
}
.notice-warp{
    width: 453px;
    margin-left:133px;
    text-align: center;
}
.notice-warp:after{
    display: block;
    content: '';
    clear: both;
}
.notice-warp .notice,
.notice-warp>h3{
    text-align: center;
}
.notice-warp>h3{
    font-size: 20px;
    margin-bottom: 24px;
}

.notice-warp>.view{
    display:block;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0051BA;
    line-height: 20px;
    text-align:center;
    margin-top:-16px;
    margin-bottom:24px;
}
.notice-warp .login{
    display: inline-block;
    width: 90px;
    height: 40px;
    background: #0051BA;
    border-radius: 2px;
    line-height: 40px;
    font-size: 14px;
    font-family: PingFangSCHeavy;
    color: #FFFFFF;
    padding:0;
    text-align: center;
}
    /*页脚部分 */
footer{
    float: left;
    display: block;
    width: 100%;
    height: 220px;
    background-color: #22375E;
    margin-top:32px;
}
footer .footer-wrap{
    position: relative;
    /* margin:0 auto; */
    box-sizing: border-box;
    height: 100%;
    padding-top: 40px;
}
footer .footer-wrap .logo{
    float: left;
    display: block;
    width: 272px;
    height: 62px;
    margin-top:32px;
    background: url(https://paper.cnpkp.com/images/bottom-logo.png) no-repeat;
    background-size: 272px 62px;
}
footer .footer-wrap .site-info{
    float: left;
    margin-left:40px;
    width: 600px;
    overflow: hidden;
}
footer .footer-wrap .site-info li{
    display: block;
    box-sizing: border-box;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(40, 66, 99, 0.85);
    line-height: 20px;
    padding-right:15px;
    margin-bottom: 8px;
}
footer .footer-wrap .site-info li .lbl{
    float: left;
    height: 20px;
    color: rgba(255, 255, 255, 0.45);
    padding-left:28px;
}
footer .footer-wrap .site-info li .info{
    float: left;
    height: 20px;
    color: #FFFFFF;
    margin-left:4px;
}
footer .footer-wrap .copyright{
   position: absolute;
   height: 20px;
   bottom: 8px;
   left:0;
   right:0;
   text-align: center;
   font-size: 12px;
   font-family: PingFangSC-Regular, PingFang SC;
   font-weight: 400;
   color: rgba(255, 255, 255, 0.45);
   line-height: 20px;
}
.footer-tel{
    background:url(https://paper.cnpkp.com/images/icon_foot_tel.png) left center no-repeat;
    background-size: 20px 20px;
}
.footer-email{
    background:url(https://paper.cnpkp.com/images/icon_foot_email.png) left center no-repeat;
    background-size: 20px 20px;
}
.footer-address{
    background:url(https://paper.cnpkp.com/images/icon_foot_address.png) left center no-repeat;
    background-size: 20px 20px;
}


.circle-button{
    position: absolute;
    display: block;
    top:50%;
    right: 40px;
    width: 24px;
    height: 24px;
    margin-top:-12px;
    text-align: center;
    line-height: 24px;
}
.plus-button{
    background: url(https://paper.cnpkp.com/images/add.png) center center no-repeat;
    background-size: 24px 24px;
}
.minus-button{
    background: url(https://paper.cnpkp.com/images/minus.png) center center no-repeat;
    background-size: 24px 24px;
}

.last-item li:last-child{
    margin-bottom: 4px !important;
}
.tit-bx{
    position: relative;
    height: 24px; line-height: 24px;
    margin: 24px 0 8px 0;
}
.tit-bx .title{
    height: 24px;
    font-size: 18px;
    font-family: PingFangSCHeavy;
    color: rgba(0, 0, 0, 0.75);
    line-height: 24px;
    border-left: 4px solid #0051BA;
    font-weight: 700;
    padding-left: 12px;
}

/* 头部样式修改 */
.top-wrap .embed-wrap .top-bg{background: none;}
.abouta-con1{padding: 0 20px;position: relative;}
.abouta-con1::before{content: '';width: 4px;height: 20px;background: url(https://paper.cnpkp.com/images/beforeBg.png)no-repeat;background-size: cover;position: absolute;left: 0;top: 0;}
.abouta-con1 h3{line-height: 20px!important;margin: 15px 0;}
.header-wrap{height: 54px;}
.top-bg .logo{width: 50%;float: left;white-space: nowrap;position: relative;}
.top-bg .logo img:nth-child(1){height: 80px;margin-top: 36px;vertical-align: middle;}
.top-bg .logo img:nth-child(2){height: 40px;margin-top: 36px;vertical-align: middle;margin-left: 20px;}
.top-bg .logo::before{content: '';position: absolute;width: 2px;height: 70px;background: #B5B5B5;top: 42px;left: 355px;}
.top-bg .search-wrap{width: 50%;float: right;white-space: nowrap;padding-top: 20px;}
.search-wrap .search {position: relative;float: left;display: block;height: 69px;line-height: 69px;margin-left: 40px;
float: right;}
.search-wrap .advance-search {position: relative;color: #fff;display: inline-block;float: right;text-align: center;
    box-sizing: border-box;height: 48px;line-height: 48px;width: 84px;padding-right: 10px;margin-top: 11px;
    font-size: 12px;font-weight: 400;background: url(https://paper.cnpkp.com/images/right_arrow_white.png) #0051BA 60px center no-repeat;
    background-size: 20px 20px; margin-left: -5px;
}
.search-wrap .search input {width: 303px;height: 48px;box-sizing: border-box;border: 1px solid rgba(255, 255, 255, 0.85);
    border-radius: 4px;background-color: #FFF;padding: 8px 50px 8px 16px;font-size: 14px;color: rgba(0, 0, 0, 0.35);border: 1px solid #0051BA;
}
.search-wrap .search .search-button {position: absolute;display: block;right: 15px;top: 50%;width: 20px;
    height: 20px;margin-top: -10px;border: none;background: url(https://paper.cnpkp.com/images/search_blue.png);
    background-size: 100% 100%;
}
.search_hot{float: right;width: 380px;height: 30px;overflow: hidden;white-space: nowrap;}
.search_hot a{display: block;float: left;line-height: 30px;padding: 0 10px;color: #002555;border-radius: 4px;}
.search_hot a.active{background: #0051BA;color: #fff;}
.header-wrap .menu .nav a{margin-right: 0;padding: 0 26px;}
.header-wrap .menu .nav a:hover,.header-wrap .menu .nav a.active{background: #003C8B;}

/* 首页样式修改 */
.swiper-container{margin: 20px 0;width: 100%;height: 400px;}
.fluid .sidebar{float: right;position: inherit;}
.swiper-slide img{width: 100%;height: 400px;}
.swiper-container>.swiper-pagination .swiper-pagination-bullet{background: #fff;}
.swiper-button-prev{background: url(https://paper.cnpkp.com/images/index_prev.png)no-repeat 8px 9px rgba(0,0,0,0.2);background-size: 24px 36px;width: 40px;height: 54px;top: calc(50% - 13.5px);}
.swiper-button-prev:hover{background-color: rgba(0,0,0,0.4);}
.swiper-button-prev::after{display: none;}
.swiper-button-next{background: url(https://paper.cnpkp.com/images/index_next.png)no-repeat 8px 9px rgba(0,0,0,0.2);background-size: 24px 36px;width: 40px;height: 54px;top: calc(50% - 13.5px);}
.swiper-button-next:hover{background-color: rgba(0,0,0,0.4);}
.swiper-button-next::after{display: none;}

.index_nav{width: 100%;height: 98px;background: #fff;}
.index_nav a{display: block;width: 25%;height: 98px;line-height: 98px;float: left;text-align: center;position: relative;}
.index_nav a::after{content: '';width: 2px;height: 54px;background: #E8E8E8;position: absolute;right: -1px;top: 22px;}
.index_nav a:last-child:after{display: none;}
.index_nav a img{vertical-align: middle;width: 48px;height: 48px;margin-right: 15px;}
.index_nav a span{vertical-align: middle;color: #686868;font-size: 20px;}


/* 公共title背景 */
.fluid .header .title, .link-wrap .header .title{border-left: 4px solid transparent;position: relative;}
.fluid .header .title::before, .link-wrap .header .title::before{content: '';width: 4px;height: 24px;position: absolute;left: -4px;top: 0;
    background: url(https://paper.cnpkp.com/images/beforeBg.png)no-repeat;background-size: cover;}

/* 首页 */
.search-wrap .advance-search{position: inherit;}
.search-wrap .search{position: inherit;}
.fluid .banner{height: 84px;}
.li-content .order{width: 14px;}
.fluid .sidebar .li-content .li-item .hot-item{width: 236px;}


/* 头部搜索框 */
.top-bg .search-wrap{position: relative;}

/* paper/list.html文章浏览页面 */
.fluid .nav-category .header .title{border-left: 4px solid #0051BA;}
.fluid .nav-category .header .title::before{display: none;}

/* 合作期刊 */
.enterprise-list>h3,.xkzj-content>h3{margin: 50px 0;padding-left: 0!important;text-align: center;color: #284263;font-weight: bold;}

/* member/index.html个人中心页面 */
.member-content .header>.title::before{display: none;}
table thead th{text-align: center;}


/* 修改头部尾部等样式 */
.top-wrap .embed-wrap{background: url(https://paper.cnpkp.com/images/top-bg.jpg)no-repeat;background-size: cover;}
.top-bg .logo::before{display: none;}
.search-wrap .search_hot{display: none;}
.top-wrap .user-wrap{display: none;}
.search-wrap .user-operation{float: right;}
.search-operation{margin-top: 30px;}
.register-button{background: none;}
footer .footer-wrap .logo{margin-top: 0;height: 94px;background-size: 272px 94px;width: 372px;background-position: center;}
footer .footer-wrap .site-info{margin-top: 10px;width: 500px;}
footer .footer-wrap .copyright{height: auto;}
footer .footer-wrap .site-info li .info{float: none;}
footer .footer-wrap .site-info li{height: auto;}
.banner_phone{display: none;}
.abouta-con1::before{background: none;}
.abouta-content .abouta-con1 .abouta-t1-img .img{width: 100%!important;}
.breadcrumb-warp{padding-left: 0!important;padding-right: 0!important;}
.breadcrumb-warp .breadcrumb{height: 20px;}
.fluid .content .article-detail .recommend-paper li>a{overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}

.fluid .sidebar .li-content{box-sizing: inherit;}
.user-button{width: 56px;}
.user-button:hover{color: #fff;}
.login-button{margin-right: 15px;}
.register-button{border: 1px solid rgba(255, 255, 255, 0.2);}
.top-bg .search-wrap{padding-top: 15px;}
.top-bg .logo img:nth-child(1){height: 43px;margin-top: 40px;}
footer .footer-wrap{padding-top: 10px;}
footer .footer-wrap .logo{display: none;}
.search-wrap .search .search-button{right: 93px;top: 70%;}
footer .footer-wrap .site-info{display: none;}
footer .footer-wrap .footer_info{font-size: 18px;text-align: center;color: #fff;line-height: 50px;}
footer{height: 100px;position: relative;background: url(https://paper.cnpkp.com/images/footer_bg.png)no-repeat;background-size: 100% 100%;}
footer .copyright{position: absolute;bottom: 0;left: 0;right: 0;text-align: center;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;color: rgba(255, 255, 255, 0.45);line-height: 20px;padding: 8px 0;background: #1F3154;}

.user-menu{width: 160px;float: right;}
.user-menu .user-image{margin-right: 10px;width: 20px;height: 20px;}
.fluid .content .thinktank-news .last-news .news-content .news-item .detail .desc .abstract{height: 40px;overflow: hidden;
    text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
.fluid .content .thinktank-news .last-news .news-content .news-item .detail img{height: 90px;}


/* 栅格系统样式冲突 */
/* input{-webkit-appearance: none; -moz-appearance: none; -o-appearance: none; appearance: none;} */
input,input:focus{box-shadow: none !important;}
.paper-submit-button{height: 84px;}
.fluid .sidebar .li-content{width: 354px;}
.fluid .content .last-news .news-content .news-item .classfication, .fluid .sidebar .li-content .li-item .order{height: 20px;}
.fluid .sidebar .li-content .li-item .order{width: 30px!important;}
.li-content .order{width: 30px;}
.article-content{width: 752px!important;min-height: 475px!important;}
.fluid .nav-category .header .title{height: 56px;}
.fluid .nav-category .nav-item li{height: 36px;line-height: 36px;}
.fluid .content .search-wrap .search-input input{width: 204px;height: 28px;}
.member-content{width: 800px!important;}
.nav-member li{height: 48px!important;}
.member-content .header{height: 72px!important;}
.member-content .header.pt-0{height: 32px!important;}
.basic-info li{height: 100px!important;}
.custom-checkbox input[type=checkbox]{vertical-align: middle;}
.form-control{width: 354px;height: 42px;border-radius: 0;}
.check-code-item .form-control{width: 354px;height: 42px;}
.license-tips{width: 354px;}
.form-enterprise .header{height: 40px;}
.primary-title{height: 40px!important;}
input[type=checkbox], input[type=radio]{margin-top: 0;vertical-align: middle;}
.breadcrumb{padding: 0;}
.aboutb-con2 .about-fourblock .about-block h4{height: 54px!important;}


/* 移动端样式 */
a {text-decoration: none;}
a:link {text-decoration: none;}
a:visited {text-decoration: none;}
a:hover {text-decoration: none;}
a:active {text-decoration: none;}
/* .container{width: 1008px;padding: 0;} */
.navbar-brand>img {height: 60%;margin-top: 3%;}
.navbar-inverse{height: 98px;}
.fluid .content{margin-right: 0;}
.fluid .banner img{width: 100%;}
.navbar-inverse{display: none;}
.fluid .sidebar .li-content .li-item .hot-item{width: calc(100% - 35px);}
.fluid .content .last-news .news-content .article-category a{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.fluid .content .last-news .news-content .news-item a{float: inherit;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.fluid .content .last-news .news-content .news-item .detail{width: 100%;height: auto;}
.first-paper{height: auto !important;}
.collapse{z-index: 999;}
.navbar-inverse .navbar-toggle{background: transparent!important;}
.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover{background: #0051BA;}
.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form{background: #eee;}
.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse{max-height: max-content;box-shadow:0 5px 10px -5px rgba(0,0,0,0.5);}
.navbar-form .form-control{height: 34px;line-height: 34px;padding: 5px 10px;vertical-align: middle;border: none;}
.input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group{height: 34px;vertical-align: middle;background: #0051BA;}
.input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group .glyphicon{color: #fff;}
.navbar-form{border: none;}
.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse{position: relative;width: 46%;float: right;background: rgba(0,0,0,0.8);margin-top: -54px;}
.navbar-inverse .navbar-nav>li>a{color: #fff;}
.underline-bar{display: none;}
.navbar-nav>li>a{margin: 0 15px;text-align: center;border-bottom: 1px dotted rgba(255,255,255,0.2);}
.container>.navbar-header{background: #0051BA;}
.navbar-inverse .search-operation{height: 44px;margin: 0 10%;}
.navbar-inverse .search-operation .advance-search {position: inherit;color: #fff;display: inline-block;float: right;text-align: center;box-sizing: border-box;
    height: 36px;line-height: 36px;width: 84px;padding-right: 10px;font-size: 12px;font-weight: 400;
    background: url(https://paper.cnpkp.com/images/right_arrow_white.png) #1062CC 60px center no-repeat;background-size: 20px 20px;margin-left: -5px;
    border-top-right-radius: 4px;border-bottom-right-radius: 4px;margin-right: 0;}
.navbar-inverse .search-operation .search {position: relative;float: left;display: block;width: calc(100% - 84px);height: 36px;line-height: 36px;float: right;}
.navbar-inverse .search-operation .search input {width: 100%;height: 36px;box-sizing: border-box;
    border-radius: 4px;background-color: #FFF;padding: 8px 40px 8px 16px;font-size: 14px;color: rgba(0, 0, 0, 0.35);border: 1px solid #fff;}
.navbar-inverse .search-operation .search .search-button {position: absolute;display: block;right: 10px;top: 18px;width: 20px;height: 20px;
    margin-top: -10px;border: none;background: url(https://paper.cnpkp.com/images/search_blue.png)no-repeat;background-size: cover;}


/* 导航项的底下指示条，并添加过渡动画*/
.underline-bar {height: 2px;width: 0%;margin: 0 auto;background-color: #ffffff ;transition:width 1.5s;-webkit-transition:width 1.5s; /* Safari */}

/* 鼠标经过导航项的时候显示过渡动画*/
li:hover>.underline-bar{width: 100%;}

/* 选中状态，指示条显示*/
.active>.underline-bar{width:100%;}

/* 媒体查询 */
@media(max-width: 768px){
    /* 公共部分，首页 */
    html, body{min-width: auto;overflow-x: hidden;}
    .navbar-inverse{display: block;background: #0051BA;box-shadow:0 5px 10px -5px rgba(0,0,0,0.1);}
    .container{width: auto;}
    .top-wrap{display: none;}
    .header-wrap{display: none;}
    .navbar-toggle{border: none;padding: 10px;}
    .container{padding-left: 15px;padding-right: 15px;}
    .fluid{margin-top: 98px!important;}
    .navbar-brand{height: 54px;padding: 10px 15px;}
    .fluid .content{float: inherit;}
    .fluid .sidebar{float: inherit;}
    footer{height: auto;}
    footer .footer-wrap{padding-bottom: 80px;}
    footer .footer-wrap .site-info{margin: 0 auto;float: inherit;text-align: left;}
    footer .footer-wrap .logo{margin: 0 auto 20px auto;float: inherit;background: url(https://paper.cnpkp.com/images/logo-bottom_phone.png) no-repeat;display: none;}
    .fluid .content{width: auto!important;}
    .fluid .sidebar{width: auto;}
    .fluid .sidebar .li-content{width: 100%;}
    .fluid .sidebar .li-content .li-item a{width: 100%;}
    .fluid .content .last-news .news-content .article-category{width: 50%;margin-right: 0;}
    footer .footer-wrap .site-info{width: 100%;}
    footer .footer-wrap{padding-left: 15px;}
    footer .footer-wrap .site-info li .info{height: auto;margin-bottom: 5px;}
    .fluid .content .last-news .news-content .news-item .detail .desc{margin-left: 0;margin-top: 15px;width: 100%;}
    .banner_pc{display: none;}
    .banner_phone{display: block;}

    /* 论文首发 */
    .step-container{display: none;}
    .paper-step-container{margin-top: 54px!important;width: auto!important;}
    .paper-conainer{width: auto!important;margin: 98px auto 0 auto!important;}
    .enterprise-info{width: auto!important;}
    .form-enterprise{width: auto!important;}
    .member-form .login-button{width: 100%;margin-right: 0;}
    .form-enterprise .form-group .form-label{width: 100%;margin-right: 0;}
    .form-control{width: 100%;}
    .select-item{width: 66px;}
    .license-tips{width: 100%;margin-top: 10px;}
    .form-group.copyright-promise{padding-left: 0!important;}
    .form-enterprise .header .title{padding-left: 0;}
    /* 文章游览 */
    .fluid .nav-category{width: 100%;margin-right: 0;margin-bottom: 15px;}
    .fluid .content .article-list .article-item a.title{height: auto;}
    .fluid .pagination li{margin-bottom: 5px;}
    .fluid .content .search-wrap{display: none;}
    /* 文章详情页 */
    .fluid .content .article-detail .recommend-paper li{width: 100%;}

    /* 文章检索 */
    .tips{width: 100%!important;}
    .search-row .search-button{width: 100%!important;margin-left: 0!important;margin-top: 10px;}
    .form-input{width: 100%!important;margin: 0!important;}
    .search-category>.sub-category{width: 100%!important;}
    .search-condition, .search-category{padding: 8px 0!important;}
    .search-category>.category,.search-category>.sub-category{width: 50%!important;}
    .search-label{width: 100%!important;margin-right: 0!important;}
    .search-container input[type='text'], .search-container input[type='date'], .search-container select{margin-left: 0!important;margin-bottom: 10px!important;}
    .circle-button{top: 80%;right: 0;}
    .split-word{margin-bottom: 10px;}

    /* 论文库 */
    .zhuku-page .rsc-content,#hotDocuTag,#hotDocuTag .words_chart{width: 100%!important;}

    /* 关于我们 */
    .article-content{width: 100%!important;}
    .abouta-con1{padding: 0;}
    .abouta-content .abouta-con1 .abouta-t1-img .img{height: auto!important;}
    .aboutb-con2 .about-fourblock .about-block{width: 48%!important;margin-right: 1%!important;}
    .aboutc-con2 .about-ul .about-li .li-left{float: none!important;margin: 5px auto!important;width: 80%!important;}
    .aboutc-con2 .about-ul .about-li .li-right{width: 100%!important;}
    .nav-about{width: 100%!important;margin-bottom: 15px!important;}

    /* 个人中心 */
    .member-content{width: 100%!important;}
    .basic-info{padding: 48px 0 0 0!important;}
    .member{padding: 24px 0!important;}
    .member .form-group .form-control{width: 100%!important;}
    .member .form-group .intro{width: 100%!important;}
    .member .button{width: 100%!important;}
    .member .form-group .form-label{width: 100%!important;}
    .basic-info li>.desc{width: calc(100% - 40px)!important;margin-left: 8px!important;}
    .nav-member{width: 100%!important;margin-right: 0!important;margin-bottom: 15px!important;}
    /* table thead th{width: auto!important;} */
    .member-content .mt-24{width: 100%;margin-top: 0!important;padding-top: 24px;border-top: 1px solid #DDD;overflow-x: scroll!important;}
    .member-content .mt-24 table{width: 800px;}
    .member-content .header{border-bottom: none!important;}
    .basic-info li{position: relative;}
    .member-content .basic-info li>a{position: absolute;right: 32px;top: 24px;}

    .nav-member>header{padding-left: 16px!important;}
    .nav-member li{padding: 12px 16px!important;}
    .member-content{padding: 16px!important;}
    table thead th:last-child{width: 80px!important;}
    table thead th{width: 80px;}
    table thead th:nth-child(1){width: 200px!important;}
    table tbody td:nth-child(1){padding: 0 5px;}

    /* 最新会员详情页 */
    .enterprise-detail{margin-left: 0!important;width: 100%!important;padding: 32px 0!important;}
    .enterprise .name{height: auto!important;}
    .enterprise-detail>.row{margin-left: 0!important;margin-right: 0!important;}
    .enterprise-detail>.row .value{width: 100%!important;height: auto!important;}

    /* 登陆页面 */
    .user-wrap{width: 100%;padding: 0 15px;margin: 134px auto 32px;}
    .user-wrap .header .title{padding-left: 0;}
    .member-form .login, .member-form .register{padding: 80px 15px;}
    .member-form-container{width: 100%;}
    .check-code-item .form-control{width: 100%;}

    /* DOI编码页面 */
    .fluid .news-detail{padding: 30px;}
    .fluid .news-detail .content img{width: 100%;margin: 10px 0;}
    .fluid .news-detail .title{height: auto;}

    
    /* 头部修改 */
    .navbar-inverse{height: 132px;}
    .fluid {margin-top: 0px!important;}
    .paper-conainer {margin: 132px auto 0 auto!important;}
    .navbar-brand {color: #534c4c;margin: 0 auto;display: block;float: none;text-align: center;height: 54px;}
    .navbar-brand>img{height: 80%;margin: 3px auto;}
    .navbar-inverse .search-operation{float: left;margin: 0;}
    .navbar-inverse .navbar-toggle{margin: 0;}
    .navbar-inverse .search-operation{max-width: calc(100% - 50px);width: calc(100% - 80px);}
    .navbar-inverse .search-operation .search{height: 32px;line-height: 32px;}
    .navbar-inverse .search-operation .search input{height: 32px;}
    .navbar-inverse .search-operation .advance-search{height: 32px;line-height: 32px;}
    .navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse{margin-top: 0;}
    .nav_top{width: 100%;height: 28px;background: #F5FAFF;}
    .nav_top p{float: left;height: 28px;line-height: 28px;font-size: 12px;color: #000;width: calc(100% - 120px);white-space: nowrap;}
    .nav_top .user-operation{float: right;margin: 4px 0;}
    .nav_top .user-operation a{width: 42px;height: 20px;line-height: 18px;}
    .nav_top .user-operation .login-button{margin-right: 10px;}
    .register-button{border: 1px solid #005BD1;color: #005BD1;}
    .nav_top .user-menu{float: right;width: auto;}
    .nav_top .user-menu>a{color: #005BD1;height: 28px;line-height: 28px;padding-right: 0;background: none;white-space: nowrap;max-width: 120px;}
    .nav_top .user-menu .user-image{width: 16px;height: 16px;margin-top: 6px;margin-right: 5px;}

}

/* 9.18更细公共样式 */
.embed-wrap .logo p{font-size: 14px;color: rgba(255, 255, 255, 0.85);line-height: 40px;}
.embed-wrap .logo p span{font-size: 14px;color: #C6E4FF;vertical-align: middle;}
.top-bg .logo img:nth-child(2){height: 35px;margin-top: 22px;margin-left: 0;}
.search-wrap .search{margin-left: 0;}
.search-wrap .search input{width: 400px;}
.important-news span{padding: 2px 5px;}
.paper-submit-button{margin-top: -8px!important;}
.fluid .header, .link-wrap .header{margin-top: 0;border-bottom: 1px solid #155FBF;}
.fluid .content .last-news,.side-item{margin-top: 24px;}
.fluid .header .title, .link-wrap .header .title{height: 30px;}
.fluid .header .more, .link-wrap .header .more{bottom: 5px;}
.fluid .content .last-news .news-content .news-item .detail .desc .authors{margin-top: 8px;}
.fluid .content .last-news .news-content .news-item .detail .desc .abstract{height: 60px;-webkit-line-clamp: 3;}
.fluid .content .last-news .news-content .news-item{position: relative;}
.fluid .content .last-news .news-content .news-item:nth-child(1){padding-right: 0;}
.fluid .content .last-news .news-content .news-item p{position: absolute;right: 0;top: 0;height: 20px;line-height: 20px;font-size: 14px;color: rgba(0, 0, 0, 0.45);}
.fluid .content .last-news .news-content .news-item:nth-child(1) p{top: 36px;}
.side-item2 .first-li-item{width: 100%;height: 55px;margin-bottom: 8px;}
.side-item2 .first-li-item img{float: left;width: 80px;}
.side-item2 .first-li-item div{float: left;width: calc(100% - 80px);padding-left: 16px;}
.side-item2 .first-li-item div p{width: 100%;line-height: 24px;color: #0149A7;height: 24px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.side-item2 .first-li-item div span{display: inline-block;background: rgba(0, 81, 186, 0.26);border-radius: 2px;line-height: 14px;color: #0051BA;padding: 2px 5px;margin: 5px 0;}
.fluid .sidebar .side-item2 .li-content .li-item .hot-item{width: 100%;height: 18px;}
.fluid .sidebar .side-item2 .li-content .li-item{padding-left: 20px;position: relative;}
.fluid .sidebar .side-item2 .li-content .li-item::before {content: '';width: 3px;height: 3px;border-radius: 50%;background: #0149A7;position: absolute;left: 0;top: 9px;}
.side-banner2 img{width: 100%;}
.news_type{width: 100%;float: left;margin-top: 24px;background: #fff;padding: 16px;}
.flex {display: flex;justify-content: space-between;}
.type_li {width: 340px;margin-bottom: 10px;border: 1px solid #E5E5E5;padding: 16px;}
.hot_title2 {height: 57px;line-height: 57px;background: url(https://paper.cnpkp.com/images/index_title_bg.jpg);background-size: cover;margin-bottom: 10px;padding: 0 15px;}
.hot_title2 span {vertical-align: middle;font-size: 23px;font-family: Microsoft YaHei;line-height: 40px;color: #fff;display: inline-block;width: 100%;text-align: center;}
.type_li li {position: relative;}
.type_li li::before {content: '';width: 3px;height: 3px;border-radius: 50%;background: #0149A7;position: absolute;left: 0;top: 16px;}
.type_li li a {display: block;height: 30px;line-height: 30px;font-size: 14px;color: #3E3E3E;padding-left: 20px;width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.type_li .more{line-height: 20px;color: #107DE3;background: url(https://paper.cnpkp.com/images/right_arrow_blue.png) rgba(0, 81, 186, 0.26) right no-repeat;
    background-size: 20px 20px;font-size: 12px;font-weight: 400;float: right;padding: 0 15px 0 5px;border-radius: 2px;}
.link-wrap{width: 100%;float: left;margin-top: 24px;background: #fff;padding: 16px;}
.link-wrap ul li{width: 245px;}
.link-wrap ul li img{border: 1px solid #DDDDDD;width: 100%;margin: 10px 0;}
.link-wrap ul li p{color: #666666;font-size: 18px;line-height: 26px;text-align: center;white-space: nowrap;}
.fluid .content .index-news .news-content .news-item{padding-right: 90px;}
.fluid .content .index-news .news-content .news-item .detail .desc{position: relative;width: calc(100% - 196px);}
.fluid .content .index-news .news-content .news-item:nth-child(1) p {top: 26px;}

/* 合作期刊 */
.enterprise-list ul li{width: 50%;float: left;margin: 24px 0;height: 200px;}
.enterprise-list ul li img{float: left;}
.enterprise-list ul li div{float: left;width: 290px;padding: 16px;position: relative;height: 100%;}
.enterprise-list ul li div p{font-size: 18px;line-height: 24px;color: #0051BA;}
.enterprise-list ul li div span{font-size: 14px;margin: 16px 0;display: block;line-height: 24px;color: #515151;}
.enterprise-list ul li div a{display: block;background: #E5EDF8;border-radius: 4px;padding: 2px 5px;font-size: 12px;line-height: 24px;color: #0051BA;position: absolute;
    left: 16px;bottom: 12px;}

/* 底部修改 */
footer{height: 150px;}
footer .footer-wrap .logo{display: block;background-size: 248px 56px;background-position: 100% 20px;}
footer .footer-wrap .site-info{display: block;color: #FFFFFF;font-size: 12px;margin-top: 16px;padding-left: 80px;line-height: 20px;}
/* footer .footer-wrap .site-info ul{display: none;} */
footer .footer-wrap .footer_info{display: none;}
footer .footer-wrap .logo{background: none;}
footer .footer-wrap .logo img{width: 70%;display: block;float: right;margin-top: 40px;}

@media(max-width: 768px){
    .navbar-brand{width: 55%;margin: 0;float: left;height: 60px;}
    .navbar-brand>img{margin: 0;width: 120%;height: auto;margin-top: 10px;}
    .navbar-inverse .navbar-toggle{margin: 10px 5px;}
    .navbar-inverse .search-operation{width: 100%;max-width: 100%;}
    .navbar-inverse .search-operation .search input{height: 36px;line-height: 36px;}
    .navbar-inverse .search-operation .advance-search{height: 36px;line-height: 36px;}
    .side-banner2{display: none;}
    .type_li{width: 100%;}
    .flex{display: block;}
    .link-wrap ul li{width: 50%;text-align: center;float: left;}
    .link-wrap ul li img{width: 90%;}
    .link-wrap ul li p{font-size: 14px;}
    .enterprise-list ul li{width: 100%;}
    .enterprise-list{padding: 8px 0!important;}
    .enterprise-list ul li img{width: 150px;}
    .enterprise-list ul li div span{margin: 0;}
    .enterprise-list ul li div{width: calc(100% - 150px);padding: 6px;}
    .enterprise-list ul li div a{left: 6px;}
    footer{height: auto;}
    footer .footer-wrap .logo{background: url(https://paper.cnpkp.com/images/bottom-logo.png)no-repeat 30% 20px;background-size: 80%;}
    footer .footer-wrap .site-info{padding-left: 15px;}
    .important-news>a{font-size: 12px;}
    .fluid .content .index-news .news-content .news-item .detail img{width: 110px;height: 92px;}
    .fluid .content .index-news .news-content .news-item .detail .desc{position: relative;width: calc(100% - 116px);padding-left: 6px;margin-top: 0;}
    .fluid .content .index-news .news-content .news-item .detail .desc .abstract{-webkit-line-clamp: 2;height: 40px;}
    footer .footer-wrap .logo{background: none;height: 62px;}
    footer .footer-wrap .logo img{margin: 20px auto 0 auto;float: none;}
    footer .footer-wrap .site-info{-webkit-transform-origin-x: 0;-webkit-transform: scale(0.95);width: 105%;text-align: center;}
    .type_inner .flex:nth-child(2){display: none;}
    .fluid .banner{overflow: hidden;border-radius: 4px;}
    .fluid .banner img{width: 120%;margin-left: -12%;}
}

</style>
